.DatePickerElement .react-datepicker-wrapper,
.DatePickerElement .react-datepicker__input-container,
.DatePickerElement .react-datepicker__input-container input {
  display: block;
  width: 100%; }

.DatePickerElement .date-picker-icon {
  float: right;
  margin-right: 6px;
  margin-top: -22px;
  position: relative; }
