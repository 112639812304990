/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
html {
  scroll-behavior: smooth;
}
body {
  background: #EEF0F8;
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  color: rgba(112, 112, 112, 1);
}

a {
  text-decoration: none;
  color: #0880e8;
}

a:hover {
  color: #2b99f8;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Poppins', sans-serif;
}

.pageHead {
  font-size: 24px;
  font-weight: 600;
  /* color: rgba(60, 60, 178, 1); */
  color: rgb(30, 115, 190);
}

.grayHead {
  font-size: 18px;
  font-weight: 600;
  color: rgba(112, 112, 112, 1);
}

/*--------------------------------------------------------------
# Top Bar
--------------------------------------------------------------*/
#topbar {
  height: 70px;
  padding: 0;
  font-size: 14px;
  transition: all 0.5s;
  overflow: hidden;
  color: rgba(112, 112, 112, 1);
  z-index: 996;
  background: #FFF;
  font-weight: 400;
}

#topbar.topbar-scrolled {
  top: -60px;
}

#topbar .contact-info a {
  line-height: 0;
  color: rgba(112, 112, 112, 1);
  transition: 0.3s;
}

#topbar .contact-info a:hover {
  text-decoration: none;
  color: rgb(2, 2, 2);

}

/*--------------------------------------------------------------
# Header Menu color
--------------------------------------------------------------*/
#header {
  /* background: rgba(60, 60, 178, 1); */
  /* background: #6c757d; */
  /* background: gray; */
  background:#006ec7;
  transition: all 0.5s;
  z-index: 997;
  /* height: 122px; */
  top: 60px;
}

#header.header-scrolled {
  background: rgba(5, 87, 158, 0.9);
  top: 0;
}

#header .logo {
  font-size: 30px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 400;
  letter-spacing: 2px;
  text-transform: uppercase;
}

#header .logo a {
  color: #fff;
}

#header .logo img {
  max-height: 130px;
  margin-top: -60px;
}

.header-inner-pages {
  background: rgba(5, 87, 158, 0.9) !important;
}

.topbar-inner-pages {
  background: rgba(6, 98, 178, 0.9) !important;
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/
.navbar {
  padding: 0;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
}

.navbar>ul>li {
  position: relative;
  white-space: nowrap;
  /* padding: 38px 35px 38px 35px; */
  padding: 38px 15px;
  border-right: 1px solid #FFF;
}

.navbar>ul>li:last-child {

  border-right: none;
}

.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 3px;
  font-size: 14px;
  color: rgba(255, 255, 255, 1);
  white-space: nowrap;
  transition: 0.3s;
  position: relative;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

 /* menu bar active color */
.navbar>ul>li>a:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -5px;
  left: 0;
  /* background-color: #f6b024; */
  /* background-color: #5ea3fd; */
  background-color: #ffffff;
  visibility: hidden;
  width: 0px;
  transition: all 0.3s ease-in-out 0s;
}

.navbar a:hover:before,
.navbar li:hover>a:before,
.navbar .active:before {
  visibility: visible;
  width: 100%;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover>a {
  color: #fff;
}
.navbar .active,
.navbar .active:hover
{font-weight: 500;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 14px;
  top: calc(100% + 30px);
  /* margin: 0; */
  margin:-20px 0 0 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #cfdfec;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
  border-radius: 8px;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  text-transform: none;
  color: #032e54;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover>a {
  color: #0880e8;
}

.navbar .dropdown:hover>ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover>ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover>ul {
    left: -100%;
  }
}

/**
* Mobile Navigation 
*/
.mobile-nav-toggle {
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

.mobile-nav-toggle.fa-x {
  color: #f6b024;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    display: none;
  }
  .modal-lg, .modal-xl {
    min-width: 90%;
}
#dimTbl thead th {
  font-size: 11px !important;
}
}
.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(5, 74, 133, 0.9);
  transition: 0.3s;
  z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
  display:none; 
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 35px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  border-radius: 10px;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}

.navbar-mobile>ul>li {
  padding: 0;
}

.navbar-mobile a,
.navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: #0665b7;
}

.navbar-mobile a:hover:before,
.navbar-mobile li:hover>a:before,
.navbar-mobile .active:before {
  visibility: hidden;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover>a {
  color: #f6b024;
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
  margin: 15px;
}

.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover>a {
  color: #f6b024;
}

.navbar-mobile .dropdown>.dropdown-active {
  display: block;
}

.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid #EBEDF3;
  border-radius: 0.42rem;
}

.card.card-custom {
  -webkit-box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%) !important;
  border: 0 !important;
  padding: 35px;
  /* background-color: #ffffffe8; */
}

.card.card-custom-menu {
  -webkit-box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%) !important;
  border: 0 !important;
  padding: 5px;
}


.form-control {
  font-size: 0.8rem;
}

.form-contro::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  opacity: 0.5 !important;
  /* Firefox */
}

@media (min-width: 1400px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1320px;
  }
}

.mt-180 {
  margin-top: 180px;
}

.btn-toggle {
  color: #fff;
  /* background-color: #3c3cb2;
    border-color: #3c3cb2; */
  background-color: #1f68b7;
  border-color: #1f68b7;
  font-weight: bold;
  text-transform: uppercase;
}
.switch-on.btn {
  padding-right: 1.5rem;
  padding-top: 2px !important;
  font-size: 15px !important;
  font-weight: bold;
}

.switch-off.btn {
  padding-right: 1.5rem;
  padding-top: 2px !important;
  font-size: 15px !important;
  font-weight: bold;
}

.btn-toggle:hover {
  color: #fff;
  background-color: #2380d3;
  border-color: #2380d3;
}

.btn-secondary {
  color: #fff;
  background-color: #cacaca;
  border-color: #cacaca;
  font-weight: bold;
  text-transform: uppercase;
  padding: 0px 40px;
}
.btn-outline-primary{
  border-color: #1f68b7;
  font-weight: bold;
  color: #1f68b7;
}
.btn-outline-primary:hover{
color: #fff;
background-color: #2380d3;
border-color: #2380d3;
}
.table td,
.table th {
  padding: 0.25rem;
  vertical-align: middle;
}

#dimTbl th {
  font-size: 12px;
  font-weight: 300;
}
#dimTbl tbody tr:first-child {
  padding-top: 8px;
}

#dimTbl a {
  font-size: 24px;
  text-align: center;
  cursor: pointer;
}

#dimTbl a.plusIco {
  /* color:#3c3cb2; */
  color: #1f68b7;
  padding: 0 10px;

}

#dimTbl a.plusIco:hover {
  color: #0665b7;
}

#dimTbl a.mnsIco {
  color: #DB0b0b;
}

#dimTbl a.mnsIco:hover {
  color: #ff0000;
}

.table th:first-child {
  text-align: right;
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
  height: 40px;
}
.btn-secHeight{
  height: 38px;
}
/* .table td:last-child {
  text-align: center;
} */

.colorRed{
  color: red;
}
.colorOrange{
  color: orange;
;
}
.react-datepicker-popper {
  z-index: 9999 !important;
}

.react-datepicker-wrapper .form-control {
  border-radius: 5px 0 0 5px;
}

.input-group-append .input-group-text {
  border-radius: 0px 5px 5px 0;
}

/* Datatable header row */
table.dataTable thead tr {
  /* background-color: #3c3cb2; */
  background-color: #006ec7; 
  /* #1f68b7; */
  color: #fff;
}

/* tracking Status */
.stBadge {
  padding: 3px 8px;
  border-radius: 5px;
  border: none;
  text-align: center;
  font-weight: 500;
}

.stRec {
  background-color: #ddc0ee;
  color: #7409a5;
}

.stDis {
  background-color: #dee9a2;
  color: #749b08;
}

.stPod {
  background-color: #d7f9ef;
  color: #0f9495;
}

.stOnhand {
  background-color: #fff3de;
  color: #ffa61a;
}

.stAlert {
  background-color: #ffe2e6;
  color: #e8486f;
}

.stOutDel {
  background-color: #baffbe;
  color: #018d14;
}

/* tracking Staiconstus */
.stIco {
  font-size: 12px;
  border-radius: 50%;
  padding: 3px 5px;
  margin: 0px 1px;
  line-height: 26px;
}

.stIco a {
  color: #fff;
}

.stOrng {
  background-color: rgba(255, 166, 15, 0.8);
}

.stOrng:hover {
  background-color: rgba(255, 166, 15, 1);
}

.stBlue {
  background-color: rgba(138, 87, 253, 0.8);
}

.stBlue:hover {
  background-color: rgba(138, 87, 253, 1);
}

.stGrn {
  background-color: rgba(46, 164, 66, 0.8);
}

.stGrn:hover {
  background-color: rgba(46, 164, 66, 1);
}

.divflex {
  display: flex;
  justify-content: space-between;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: auto !important;
  
}

.dimLink{
  font-weight: bold;
  font-size: small;
}
.btnTextSize {
  /* margin: 0;
  position: absolute; */
  width: auto;
}

.margintop1 {
  margin-top: 1rem;
}

.addressSize {
  width: 100%;
  height: 100%;
  color:#333333;
}

.chkboxSize {
  top: .8rem;
  width: 1.1rem;
  height: 1.1rem;
}

.chkLabel {
  display: inline-block;
  padding-bottom: 1rem;
  padding-top: 0rem;
  margin-left: 0.5rem;
}

#quoteOut {
  -webkit-box-shadow: 0px 0px 5px 1px #dedede;
  -moz-box-shadow: 0px 0px 5px 1px #dedede;
  box-shadow: 0px 0px 5px 1px #dedede;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  line-height: 18px;
}

.QuoteLabel {
  font-family: inherit;
  font-weight: bold;
  color: black;
  font-size: 13px;
  
}

.CompCode {
  font-family: inherit;
  font-weight: bold;
  color: #1f68b7;
  font-size: 14px;
  margin-bottom: 0px;
}

.tutorialTextGreen {
  font-family: inherit;
  font-weight: bold;
  color: green;
  font-size: 16px;
  margin-bottom: 0px;
  background-color: rgb(240, 240, 240);
   border-color: rgb(247, 246, 246);
  /*border-style: groove; */
  /* border-style:   : dotted;  : dashed;: solid;: double;: groove;: ridge;: inset;: outset;: none;: hidden: dotted dashed solid : groove;
  border-color: rgb(221, 220, 220);*/
}

.tutorialTextRed {
  font-family: inherit;
  font-weight: bold;
  color: red;
  font-size: 14px;
  margin-bottom: 0px;
  background-color: rgb(240, 240, 240);
}

.QuoteTableLabel {
  font-family: inherit;
  font-weight: bold;
  font-size: 13px;
  color:#555555;
  /* width: 50%; */
}

#Labelbold, .Labelbold {
  font-weight: 800;
  margin-bottom: 0px;
  font-size: 14px;
  color:#000;
}

.bpad {
  margin-bottom: 3px;
}

.tab1 {
  tab-size: 2;
}

.tab2 {
  tab-size: 4;
}

.tab4 {
  tab-size: 8;
}

.mr1 {
  margin-right: 1rem;
}
.ml2 {
  margin-left: 2rem;
}

#LabelRegular, .LabelRegular {
  margin-bottom: 0px;
}

#Labelbold15Blue, .Labelbold15Blue {
  /* margin-left: 1rem; */
  font-family: inherit;
  font-weight: bold;
  color: #1f68b7;
  font-size: 15px;
  width: 50%;
}

#LabelboldBlue15, .LabelboldBlue15 {
  font-family: inherit;
  font-weight: bold;
  color: #1f68b7;
  font-size: 15px;
  /* width: 50%; */
}

#QuoteTableData, .QuoteTableData {
  font-family: inherit;
  font-weight: bold;
  color: #1f68b7;
  font-size: 13px;
  /* width: 50%; */
}

.QuoteInfo{
  font-family: inherit;
  font-weight: 500;
  color: #1f68b7;
  font-size: 10px;
  /* width: 50%; */
}

.passErrMsg{
  font-family: inherit;
  font-weight: 500;
  color:  red;
  font-size: 13px;
  /* width: 50%; */
}

.QuoteHead {
  font-family: inherit;
  font-weight: bold;
  color: rgb(219, 57, 7);
  font-size: 10px;
  /* width: 50%; */
}

.boldBlack{
  font-weight: bold;
  color: black;
  font-family: inherit;
  font-size: 14px;
}
.bold14{
  font-weight: bold;
  font-family: inherit;
  font-size: 14px;
}

#QuoteLabel15Black, .QuoteLabel15Black{
  font-family: inherit;
  font-weight: bold;
  color: black;
  font-size: 15px;
}

.QuoteLabelData{
  font-family: inherit;
  font-weight: bold;
  color: #1f68b7;
  font-size: 13px;
}

#QuoteLabel20, .QuoteLabel20{
  font-family: inherit;
  font-weight: bold;
  color: #1f68b7;
  font-size: 20px;
}

#QuoteLabelDataLarge, .QuoteLabelDataLarge {
  font-family: inherit;
  font-weight: bold;
  color: #1f68b7;
  font-size: 20px;
}

.QuoteLabel18 {
  font-family: inherit;
  font-weight: 500;
  color: #1f68b7;
  font-size: 18px;
}

.backgroundlight{
  background-color: #e7e4e4;
}
#QuoteTable, .QuoteTable {
  width: 100%
}

.address-shadow {
  padding: 25px 25px 25px 25px;
  -moz-box-shadow: 3px 3px 5px 6px #ccc;
  -webkit-box-shadow: 3px 3px 5px 6px #ccc;
  box-shadow: 3px 3px 5px 6px #ccc;
}

@media print {
  .pagebreak { page-break-before: always; } /* page-break-after works, as well */
}

@media print {
  div {
    break-inside: avoid;
  }
  #invprintAll {
    display: none;
  }
  .excludeInPrint{
    display: none;
  }
}
.loadSpin{
  z-index: 99999;
  top: 50%;
  left: 50%;
  position: fixed !important;

}
.loadSpin::before{
content: '';
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
z-index: 999;

}

.modal-header{
  background: #1f68b7;
}

.modal-title{
  color: #fff;
  line-height: 1;
}
.modal-header .close {
  color: #fff;
  font-size: 20px;
}

/* #dimTbl th{
  background: #0665b7;
  color: #FFF;
} */
#dimTbl{
  margin-bottom: 0px !important;
}
#dimTbl .darkHead{
  background-color: #1f68b7 !important;
  padding: 10px 0;
  color: #fff;
  line-height: 28px;
  font-weight: 500;
}
#dimTbl tr:last-child{
  border:none;
}
.brdrTbl{
  border:1px solid #dee2e6;
}
#dimTbl table {
  width: 100%;
}

#dimTbl thead, #dimTbl tbody, #dimTbl tr, #dimTbl td, #dimTbl th { display: block; }

#dimTbl tr:after {
  content: ' ';
  display: block;
  visibility: hidden;
  clear: both;
}

#dimTbl thead th {
  height: 30px;
  font-weight: bold;
  font-size:13px;

  /*text-align: left;*/
}

#dimTbl tbody {
  height: 200px;
  overflow-y: auto;
}

#dimTbl tbody tr td:first-child{
  text-align: right;
  padding-top: 10px;
}

#dimTbl tbody td, #dimTbl thead th {
  float: left;
}

.quoteOut {
  -webkit-box-shadow: 0px 0px 5px 1px #dedede;
  -moz-box-shadow: 0px 0px 5px 1px #dedede;
  box-shadow: 0px 0px 5px 1px #dedede;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  line-height: 18px;
}
.covAdd{
  padding-left:20px;
}
.covAdd .covIco{
  margin-left:-20px;
  color:#ababab;

}
.brdrRight{
  border-right:1px solid rgba(0,0,0,0.1);
}

.bluTxt{
  color: #1f68b7 !important;
}
.bounce:hover {
  display: inline-block;
  position: relative;
  -moz-animation: bounce 0.5s infinite linear;
  -o-animation: bounce 0.5s infinite linear;
  -webkit-animation: bounce 0.5s infinite linear;
  animation: bounce 0.5s infinite linear;
  font-size: 18px;
  color:#ff9d00;
  cursor: pointer;

}
@-webkit-keyframes bounce {
    0% { top: 0; }
    50% { top: -0.2em; }
    70% { top: -0.3em; }
    100% { top: 0; }
}
@-moz-keyframes bounce {
    0% { top: 0; }
    50% { top: -0.2em; }
    70% { top: -0.3em; }
    100% { top: 0; }
}
@-o-keyframes bounce {
    0% { top: 0; }
    50% { top: -0.2em; }
    70% { top: -0.3em; }
    100% { top: 0; }
}
@-ms-keyframes bounce {
    0% { top: 0; }
    50% { top: -0.2em; }
    70% { top: -0.3em; }
    100% { top: 0; }
}
@keyframes bounce {
    0% { top: 0; }
    50% { top: -0.2em; }
    70% { top: -0.3em; }
    100% { top: 0; }
}


.btn-dark-gray{
  background-color: #808080;
  border-color: #808080;
  color: #fff;
  white-space: nowrap;
  font-size: 12px;
}

.btn-dark-gray:hover{
  background-color: #8b8989;
  border-color: #808080;
  color: #fff;
}

.primary-text{
  text-transform: none;
  font-size: 12px;
}

.transformNone{
  text-transform: none !important;
}

.btn-disabled{
  width: 115px;
  cursor:not-allowed !important;
  font-size: 14px !important;
  
}


.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: white !important;
  border: 0px;
  background-color: red;
  padding:2px 2px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
    color: white !important;
    /* border: 1px solid rgba(0, 0, 0, 0.3); */
    background: #1E73BE !important;
	border-radius:5px;
	border:0px !important;
	padding:2px 2px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
    padding:2px 2px !important;
    border-radius: 5px;
	margin:0 2px;
}

/* Side Navigation Menu */
.sideNav{
  list-style: none;
  padding:0;
  margin:0;
}
.sideNav li {
line-height: 50px;
border-bottom:1px solid #ccc;
}
.sideNav li:last-child{
border:none;
}
.sideNav li a {
text-decoration: none;
font-weight:500;
font-size:15px;
display: block;
color: rgba(112, 112, 112, 1);
  padding:0 15px;


}
.sideNav li a:hover {
text-decoration: none;
  transition: color .2s ease,background-color .2s ease;
  background-color: rgba(245,248,250,.8);
}
.sideNav li a:active, .sideNav li a:focus {
text-decoration: none;
}
.sideNav li .active{
transition: color .2s ease,background-color .2s ease;
  background-color: #006ec7;
color:#fff;
}

.sideNav li .active:hover{
transition: color .2s ease,background-color .2s ease;
  background-color: #006ec7;
opacity:0.8;
color:#fff;
}


/*----------------------------------------------
				Corporate Directory
----------------------------------------------*/

#corpDirTbl{
	margin-bottom:0px !important;	
	border:1px solid #ccc;
}

#corpDirTbl .mainHead{
	color:#fff;
	text-align:center;
	background:gray;

}
.mainHead th{
	padding:8px;
	font-size:18px;
	font-weight:700;
}
#corpDirTbl .subHead{
	color:#fff;
	background:#363a3d;
}
#corpDirTbl .subHead2{
	color:#fff;
	text-align:center;
	background :#006ec7;
  /* #3c3cb2; */
	font-size:16px;

}
#corpDirTbl .emerg{
	color:#fff;
	text-align:center;
	background:#cc0000;
	font-size:16px;

}
.emergTbl{
	width:100%;
	background:none !important;
	border:none;

}
.emergTbl td{
	text-align:center !important;
}
.emergTbl td:last-child{
	text-align:left;
}
.emergTbl h5{
	font-size:16px;
	font-weight:800;
}
.emergTbl h6{
	font-size:15px;
	font-weight:600;
}
.emergTbl .blue{
	color:#006ec7;
}
.emergTbl .red{
	color:#ff0000;
}
.corpInfo h5{
	font-size:16px;
	font-weight:800;
}
#corpDirTbl td{
	padding:5px;
	text-align:lefT;
}
.wordSpacing{
  word-spacing: .5rem;
  letter-spacing: normal;
}


@media print {
  .mainHead th{
      color:#fff;
      background-color:gray !important;
      -webkit-print-color-adjust: exact;
  }
  
  .subHead th{
    color:#fff;
    background:#363a3d !important;
    -webkit-print-color-adjust: exact;
  }
  .subHead2 th{
      color:#fff;
      text-align:center;
      background:#006ec7 !important;
      -webkit-print-color-adjust: exact;
  }
  .emerg th{
      color:#fff;
      text-align:center;
      background:#cc0000 !important;
      -webkit-print-color-adjust: exact;
  }
  .table-striped tbody tr:nth-of-type(odd) td{
    background-color: rgba(0,0,0,0.05) !important;
    -webkit-print-color-adjust: exact;
  }
  .table-striped tbody tr td a{
    text-decoration: none;
  }

  #corpDirTbl{
    font-size: 13px !important;
  }

  #carrierDirectoryTbl{
    font-size: 13px !important;
  }

  body#cdPrintWin .col-md-6{
    /* margin: 0mm !important; */
    width: 30%;
  }

}



/*----------------------------------------------
				Material react table  custom Change
----------------------------------------------*/

.css-suriyt-MuiTableRow-root {
 
  background-color: #006ec7 !important;
}


/*----------------------------------------------
				Customer SOPs
----------------------------------------------*/
.brdrBtm{
	border-bottom:1px solid #ccc;
}
.sopBox label, .sopBox p{
	margin:0.5rem 0!important;
}
.sopBox p{
	text-align:justify;
}
.sopBox .list{
	margin-left:0px;
	padding-left:15px;
}
.sopBox .list li{
	margin:0.5rem 0;
	text-align:justify;
}
.sopBox2 label{
	margin-bottom:0.2rem;
  font-weight: bold;
  color: #1e73be;
}
.sopBox2 p{
	font-size:14px;
	/* font-weight:500; */
	margin-bottom:0.2rem;
	text-align:justify;
}
.sopBox2 hr{
	margin:0.1rem 0 0.5rem 0;
}
.sopBox2 .list{
	margin-left:0px;
	padding-left:15px;
	font-size:14px;
	font-weight:500;

}
.sopBox2 .list li{
	margin-bottom:0.5rem;
	text-align:justify;
}
.sopBox2 .pagination {
	margin-bottom:0px;
}

.vrootSide{
  height:35px;
  width:auto;
}
.ico20Blu{
  font-size:20px;
  color:rgba(60, 60, 178, 1);
  margin-right:5px;
}

/*-------------------
sidebar
---------------*/
#sidebar {
  min-width: 330px;
  max-width: 330px;
  /* background: #3c3cb2; */
  background:#fff;
  color: #707070;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s; 
   position:relative;
   border-radius:10px;
   -webkit-box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
    box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%) !important;
    border: 0 !important;
    /* padding: 35px; */
  }
 
  #sidebar.active {
    min-width: 80px;
    max-width: 80px;
    text-align: center; 
	}
    #sidebar.active ul.components li {
      font-size: 14px; 
	 }
    
	#sidebar.active ul.components li a {
        padding: 15px 0; 
		 font-size: 24px;
		
	}
    #sidebar.active ul.components li a i {
          margin-right: 0;
          display: block;
          font-size: 24px;
	}
        	
    #sidebar.active .logo {
      padding: 10px 0; 
	  }
    #sidebar.active span {
      display: none; 
	  }
  #sidebar .logo {
    display: block;
    color: #fff;
    font-weight: 900;
    padding: 10px 30px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s; 
	}
@media (prefers-reduced-motion: reduce) {
      #sidebar .logo {
        -webkit-transition: none;
        -o-transition: none;
        transition: none; 
		}}
  #sidebar ul.components {
    padding: 0;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s; }
@media (prefers-reduced-motion: reduce) {
    #sidebar ul.components {
        -webkit-transition: none;
        -o-transition: none;
        transition: none; 
		
	} }
	#sidebar ul li {
		font-size: 16px; 
	}
    #sidebar ul li > ul {
      margin-left: 10px; 
	}
    #sidebar ul li > ul li {
        font-size: 14px;
	}
    #sidebar ul li a {
      padding: 10px 20px;
      display: block;
      color: #707070;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1); 
	  font-size:14px;
	}
    #sidebar ul li a i {
        margin-right: 15px; 
	}
@media (max-width: 991.98px) {
    #sidebar ul li a i {
        display: block; 
	} }
      
    #sidebar ul li.active > a {
     background-color: #006ec7;
      color: #fff; 
	  }
	 /* side bar color menu colors  */
	#sidebar li a {
		text-decoration: none;
		font-weight:500;
		font-size:15px;
		display: block;
		/* color: rgba(112, 112, 112, 1); */
    color: #006ec7; 
		 padding:0 15px;
}
	#sidebar li a:hover {
		text-decoration: none;
		transition: color .2s ease,background-color .2s ease;
		/* background-color: rgba(245,248,250,.8); */
    background-color: rgb(220, 224, 224);
	}
	#sidebar li a:active,  #sidebar li a:focus {
		text-decoration: none;
	}
  #sidebar li:first-child .active, 
  #sidebar li:first-child .active:hover, 
  #sidebar ul li:first-child a:hover
  {
    border-radius: 5px 5px 0px 0px;
  }
  /* sidem menu mouse over color */
	#sidebar li .active{
		transition: color .2s ease,background-color .2s ease;
		background-color: #006ec7; 
    /* #1f68b7;  */
    /* #3c3cb2; */
		color:#fff;
	}
 
	#sidebar li .active:hover{
		transition: color .2s ease,background-color .2s ease;
    background-color: #006ec7;
		/* background-color: #5c5959fc;  */
    /* #1b5ea8; */
     /* #3c3cb2; */
		opacity:0.8;
		color:#fff;
	}	  

@media (max-width: 991.98px) {
    #sidebar {
		min-width: 80px;
		max-width: 80px;
		text-align: center;
		/* margin-left: -80px !important;  */
	}
    #sidebar.active {
        margin-left: 0 !important; 
		
	} }
		
	#sidebar .custom-menu {
		display: inline-block;
		position: absolute;
		top: 20px;
		right: 0;
		margin-right: -20px;
		-webkit-transition: 0.3s;
		-o-transition: 0.3s;
		transition: 0.3s; 
	}
    @media (prefers-reduced-motion: reduce) {
      #sidebar .custom-menu {
        -webkit-transition: none;
        -o-transition: none;
        transition: none; } }
    #sidebar .custom-menu .btn {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      position: relative; 
	  font-size: 14px; 
	}
    #sidebar .custom-menu .btn i {
        margin-right: -40px;
        font-size: 14px; 
	}
      #sidebar .custom-menu .btn.btn-toggle {
        background: transparent;
        border-color: transparent; 
	}
        #sidebar .custom-menu .btn.btn-toggle:after {
          z-index: -1;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          content: '';
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
          /* background: gray; */
          background: #006ec7;
           /* #3445b4; */
          border-radius: 10px;
		}
        #sidebar .custom-menu .btn.btn-toggle:hover, #sidebar .custom-menu .btn.btn-toggle:focus {
          background: transparent !important;
          border-color: transparent !important; 
		  box-shadow: none;
		}
		  

a[data-toggle="collapse"] {
  position: relative; }

.dropdown-toggle::after {
  display: none;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%); }

@media (max-width: 991.98px) {
  #sidebarCollapse span {
    display: none; } 
    .custom-menu{
      display:none !important;
    }
  }

.footer {
  padding: 0 30px; }
  @media (max-width: 991.98px) {
    .footer {
      display: none; } 
      
    }

#content {
  width: 100%;
  padding: 0;
  /* min-height: 100vh; */
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s; } 

  #sidebar ul li:last-child a {
    border-bottom:none;
   }
#sidebar ul {
    margin-bottom:0;
   }  

   /*--Approved Motor Carrier --*/
#apMotor p{
  margin-bottom:10px;
}
#apMotor strong{
  color:#000;
}
#apMotor h6{
  font-size:16px !important;
  font-weight:700;
  margin-bottom:5px;
}
#apMotor h5{
  color:#000;
  font-size:18px !important;
  font-weight:700;
  margin-bottom:5px;
}



.splTxt {
  color:#ef8d10;
  font-weight:500;
  font-size:16px;
}
.splTxt i{
  color:#ff0000!important;
  font-size:16px;
}
.apHead h4{
  color:#006ec7;
  font-weight:800 !important;
  font-size:18px;
  /* white-space: nowrap; */
}
.apHead p{
  font-weight:500;
  font-size:14px;
  margin:5px 0;
  }
.wtExp{
  background:#fff5f8;
  color:#d40034;
  padding:6px;
  border-top:1px solid #fbbfce;
  border-bottom:1px solid #fbbfce;
  font-weight:800;
}    
.wtExp span{
  font-size:15px;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid #dee2e6;
  border-top-left-radius: .50rem;
  border-top-right-radius: .50rem;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #fff;
  background-color: #006ec7;
  border-color: #dee2e6 #dee2e6 #fff;
}
.tab-content {
  border: 1px solid #e9ecef;
  border-top: 0px;
}
.nav-tabs .nav-link:hover {
  border-color: #e9ecef #e9ecef #dee2e6;
  background-color: #f8f9fa;
  color: #000;
}

.nav-tabs .nav-link.active .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
  background-color: #006dc7a9;
  color: #fff;
}

.dataTables_scrollHead, .dataTables_scrollHeadInner, .dataTable{
  width: 100% !important
  }

  
.tariffReportDiv {
  border: 1px solid #e9ecef;
}

.regList, .regList ul {
  list-style: none;
}
.regList li{
    margin:4px 0;
}
.regList li::before {
  content: "\2022";
  color: #006ec7;
  /* #3c3cb2; */
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
  font-size:15px;
}

.form-inline{
  width: auto !important;
  display: inline !important;
}

/*-- Executive --*/
.exeCard{
  height:auto;
  text-align:center;
}
.exeCard img{
  padding:5px;
  border:3px solid #efefef;
  width:100%;
  
}
.exeCard .exeHead {
  font-weight:bold;
}
.exeCard .exeHead h6{
  font-size:18px;
  color:rgb(60, 60, 178);
  font-weight:700;
  margin-bottom:3px !important;
}
.exeDesc{
  font-size:15px;
  line-height:26px;
}
#exeTbl thead tr th{
  font-weight:600;
  background:#006ec7;
  /* #3c3cb2; */
  color:#fff;
  
  text-align:left;
}
#exeTbl th, #exeTbl td{
padding:8px;
}
#exeTbl tr td{
text-align:left;
}
.location i {
font-size: 14px;
background: #006ec7;
 /* #3c3cb2; */
color: #fff;
float: left;
width: 30px;
height: 30px;
display: flex;
justify-content: center;
align-items: center;
border-radius: 50%;
}
.location h6 {
padding: 0;
font-size: 17px;
font-weight: 700;
margin-bottom: 8px;
color: #006ec7;
 /* #3c3cb2; */
text-transform:uppercase;
}
.location p {
padding: 0 0 0 50px;
margin-bottom: 10px;
font-size: 14px;
color: #444444;
}
.grayCard{
  border-radius:5px;
  /* background:#efefef; */
  text-align:center;
  /* padding:10px; */
  /* padding-bottom:20px; */
}
.grayCard i{
  font-size: 20px;
  background: #939393;
  color: #fff;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin:auto;
  text-align:center;
}
.grayCard a{
  font-size:13px;
  font-weight:500;
}
.grayCard:hover i{
  font-size: 22px;
  width: 50px;
  height: 50px;
  background: #006ec7;
  box-shadow:  2px 2px 2px 2px #ccc;
}
.grayCard:hover a span{
  /* text-shadow:  2px 2px #ccc; */
  font-weight:700;
}
.border-right{
  border-right: 1px solid #e5e5e5;
}
.brdrRight{
	border-right:1px solid #ccc;
}
.input-control-inline {
  display: inline-flex;
  /* margin-right: 1rem; */
}

.btn-primary{
  color: #fff;
  background-color: #006ec7;
  border-color: #006ec7;
  font-weight: bold;
  text-transform: uppercase;
}
.btn-secondary{
color: #fff;
background-color: #cacaca;
border-color: #cacaca;
font-weight: bold;
text-transform: uppercase;
padding:0px 40px;
}

.quoteIdHis, .bookIdHis, .invoiceDet{
  font: inherit;
  cursor: pointer;
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  background:#006ec7;
  padding: 0 0 20px 0;
  color: #fff;
  font-size: 14px;
}
#footer .footer-top {
  /* background: rgba(30,115,190,0.5); */
  padding: 40px 0 20px 0;
  border-bottom:1px solid #4092d5;
}
#footer .footer-top .footer-info {
   margin-bottom: 20px; 
}
#footer .footer-top .footer-info h3 {
  font-size: 18px;
  margin: 0 0 20px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-weight: 700;
}
#footer .footer-top .footer-info p {
  font-size: 14px;
  /* line-height: 24px; */
  margin-bottom: 0;
  color: #fff;
}
#footer .footer-top .social-links a {
  font-size: 16px;
  display: inline-block;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  line-height: 1;
  padding: 10px 0;
  margin-right: 6px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
  -webkit-box-shadow: 0px 0px 5px 1px #009dc7; 
  box-shadow: 0px 0px 5px 1px #009dc7;
}

#footer .footer-top .social-links a.fb {
  background:#3656a1;
}
#footer .footer-top .social-links a.twtr {
  background:#37b1e2;
  /* font-size: 14px !important; */
}
#footer .footer-top .social-links a.lnkd {
  background:#0d6a9c;
}
#footer .footer-top .social-links a.insta {
  background:#ac3e8c;
}



#footer .footer-top .social-links a:hover {
/* background: #f6b024; */
color: #fff;
text-decoration: none;
}
#footer .footer-top .social-links a i{
    -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
#footer .footer-top .social-links a:hover i{
-webkit-transform: scale(1.3);
  -moz-transform: scale(1.3);
  -ms-transform: scale(1.3);
  -o-transform: scale(1.3);
  transform: scale(1.3);
}

#footer .footer-top h4 {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  position: relative;
  padding-bottom: 12px;
}
#footer .footer-top .footer-links {
   margin-bottom: 20px; 
}
#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
#footer .footer-top .footer-links ul i {
  padding-right: 6px;
  color: #5db1f9;
  font-size: 10px;
  line-height: 1;
}
#footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}
#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}
#footer .footer-top .footer-links ul a {
  color: rgba(255, 255, 255, 0.75);
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}
#footer .footer-top .footer-links ul a:hover {
  color: #fff;
}
#footer .footer-top .footer-contact {
  margin-bottom: 20px;
}
#footer .footer-top .footer-contact p {
  line-height: 26px;
}
#footer .copyright {
  padding-top: 20px;
}
.aemca img{
    height:64px;
}
.infoLnks{
    text-align:center;
    padding:10px;
    padding-bottom:20px;
    cursor:pointer;
    opacity:0.8;
    transition: 0.3s;
}
.infoLnks:hover{
    opacity:1;
}
.infoLnks i{
    font-size: 50px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin:auto;
    text-align:center;
}
.infoLnks .infWtr{
    color: #c7db4b;
}
.infoLnks .infTut{
    color: #ffba00;
}
.infoLnks:hover .infWtr{
    color: #c7db4b;
}
.infoLnks:hover .infTut{
    color: #ffba00;
}
.infoLnks a{
    font-size:12px;
    color:#fff!important;
}
/*--Back to Top--*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: #0880e8;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  transition: all 0.4s;
}
.back-to-top i {
  font-size: 16px;
  color: #fff;
  line-height: 0;
}
.back-to-top:hover {
  background: #2194f7;
  color: #fff;
}
.back-to-top.active {
  visibility: visible;
  opacity: 0.8;
}
.back-to-top.active:hover {
  visibility: visible;
  opacity: 1;
}
.nSales {
  border-radius: 5px;
  background: #efefef;
  text-align: center;
  padding: 20px;
}
.nSales h6 {
  padding: 0;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
}
.nSales h5 {
  padding: 0;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 5px;
  color: #3c3cb2;
  text-transform:uppercase;
}
.nSales p {
  font-size: 14px;
  margin-bottom:0;
}

/*--------------------------------------------------------------
# Service Cards
--------------------------------------------------------------*/
.servCards {
  padding-top: 0;
  position: relative;
  z-index: 100;
}
.servCards .icon-box {
  padding: 30px 30px;
  position: relative;
  overflow: hidden;
  background: #fff;
  box-shadow: 5px 10px 29px 0 rgba(68, 88, 144, 0.2);
  transition: all 0.3s ease-in-out;
  border-radius: 10px;
  min-height:350px;
}
.servCards .icon {
  margin: 0 auto 20px auto;
  display: inline-block;
  text-align: center;
}
.servCards .icon i {
  font-size: 36px;
  line-height: 1;
  color: #64cdfd;
}
.servCards .title {
  font-weight: 700;
  margin-bottom: 10px;
  font-size: 18px;
}
.servCards .title a {
  color: #05579e;
}
.servCards .description {
  font-size: 13px;
  line-height: 24px;
  margin-bottom: 0;
  color: #777777;
}
.servCards .btn-wrap  {
    text-align: right;
    position:absolute;
    bottom:30px;
    right:30px;
}



.btn-lrnMore {
    background: #006ec7;
    display: inline-block;
    padding: 8px 35px 10px 35px;
    border-radius: 50px;
    color: #fff;
    transition: none;
    font-size: 13px;
    font-weight: 400;
    transition: 0.3s;
}
.btn-lrnMore:hover {
  background: #2b99f8;
  color: #fff !important;
  font-size: 15px;
}

.buttonoutline {
  border: 2px solid #fff !important;
}
.buttonTrack {
  /* background-color: #bbb;
  display: block; */
  /* margin: 10px 0; */
  padding: 10px;
  width: 100%;
  margin-bottom: 10px;
}

.bookI5 {
  padding: 50px 0;
  background: #006ec7;
  color:#fff;
}

.bookII {
  background: #006ec7;
  color:#fff;
}
.bookI5 h5 {
  font-size: 22px;
  margin: 0;
  padding: 0;
  line-height: 1.6;
  font-weight: 600;
}
.bookI5 form input {
  /* border: 2px; */
  /* padding: 4px; */
   border-radius: 50px;
   padding-top:5px;
   padding-bottom:5px;
}
.bookI5 form input[type=submit]{
    border:2px solid #fff !important;
    color:#fff!important;
}
.bookI5 form input[type=submit]:hover{
    background: #006ec7;
    color: #fff !important;
}
.aboutSec{
    background:#fff;
}
.aboutTxt{
    padding:30px 20px 30px 40px;
}
.aboutSec h5{
     font-size:24px;
     line-height:30px;
     color: #05579e;
     font-weight:800 !important;
     margin-bottom:15px;
}
.aboutSec p{
    text-align:justify;
    line-height:26px;
    font-size:14px;
}
.aboutSec .btn-wrap  {
    text-align: right;
    position:absolute;
    bottom:30px;
    right:20px;
}
.homeSlider{
    margin-top:156px;
}
.carousel-inner {
  max-height: 500px;
}
.carousel-inner img{
    max-height: 500px;
}
.homeSlider .carousel-control-prev, .homeSlider .carousel-control-next{
 /*   display:none;*/
    font-size: 24px;
}
.carousel-caption{
    text-align:right;
    right:8%;
    left:8%;
    
}
.carousel-caption h2{
    font-size:36px;
    color:#fff;
    
}
/* .contact-info {
  width: 50% !important ;
} */
.contact-info .form-control, .contact-info .btn{
    border-radius:50px;
}
.contact-info .btn{
    padding:5px 18px;
    font-size:13px;
    font-weight:500;
}
.contact-info .btnSignUp{
    color: #fff;
    background-color: #cd3333;
    border-color: #cd3333;
    text-transform: uppercase;
}
.contact-info .btnSignUp:hover{
    background:#e30808;
}

/*--------------------------------------------------------------
    News Feed
--------------------------------------------------------------*/
.newsTable{
  width: 100%;
}
.newsDesc{
  width: 80%;
}
.newsHead{
  padding: 1.25rem;
  text-align: center !important;
}
.newsBtnSpc{
  margin: .1rem; 
  /* 25rem; */
  font: inherit;
  /* font-size: 11pt; */
  text-transform: unset !important;
}
.newsTable tbody tr td:nth-child(2){
  text-align: center;

}

.table .thead-light th {
  color: #ffffff;
  background-color: #006ec7 !important;
  font-size: medium;
 /*  border-color: #495057; */
} 

.newsfeedFocus{
  border-color: #00a116;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(0, 255, 55, 0.6);
}


/*--------------------------------------------------------------
    News
--------------------------------------------------------------*/
.newsSlider{
  background: linear-gradient(rgba(5, 74, 133, 0.8), rgba(5, 74, 133, 0.9)), url("../img/flight_1.jpg") fixed center center;
  background-size: cover;
  /* padding: 30px 0; */
  color:#fff;
}
.newsSlider .carousel {
  width: 80%;
  margin: 0 auto;
  padding-bottom: 50px;
}
.newsSlider .carousel .carousel-item {
  font-size: 14px;
  text-align: center;
  overflow: hidden;
  min-height: 250px;
}
.newsSlider .carousel strong {    
  padding: 30px 0 10px;
  font-size:18px;
  line-height:1.8;
  
}
.newsSlider .carousel p {    
  padding: 30px 0 10px;
  font-style: italic;
  font-size:16px;
  line-height:1.8;
}
.newsSlider .carousel p a{    
  font-style: normal;
  font-size:18px;
  color:#fff;
  font-weight:600;
}
.newsSlider .carousel p a:hover{    
  text-decoration:underline;
}
.newsSlider .carousel-indicators li, .newsSlider .carousel-indicators li.active {
  width: 11px;
  height: 11px;
  margin: 1px 5px;
  border-radius: 50%;
}
.newsSlider .carousel-indicators li {    
  background: #e2e2e2;
  border: none;
}
.newsSlider .carousel-indicators li.active {        
  background: #fff;        
}
@media (max-width: 767px) {
  #header {
    top: 70px;
  }
  #topbar {
    height: 70px;
}
#topbar .row{
 text-align:right;
 margin-left:0px;
 margin-right:0px;
}
#header .logo img {
  max-height: 80px;
  margin-top: -30px;
}

.bookI5 {
  padding: 20px 0;
}
.mt-180 {
  margin-top: 150px;
}
.newsBtnSpc{
  width:92% !important;
}
  } 
  @media (min-width: 768px) and (max-width: 998px)  {
    #topbar .row{
      margin-left:150px;
     
     }
     .newsBtnSpc{
      width:92% !important;
    }
    .homeSlider {
      margin-top: 130px !important
    }

  }  
  .carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 20px solid transparent;
    opacity: .5;
    transition: opacity .6s ease;
}
.carousel-indicators .active {
  opacity: 1;
}
.visually-hidden{
  display: none;
}

#webAccontTbl tbody tr td:nth-child(3), #webAccontTbl tbody tr th:nth-child(3),
#webAccontTbl tbody tr td:nth-child(6), #webAccontTbl tbody tr th:nth-child(6){
  width:100px !important;
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: break-all;
}

#webAccontTbl tbody tr td:nth-child(9), #webAccontTbl tbody tr th:nth-child(9),
#webAccontTbl tbody tr td:nth-child(10), #webAccontTbl tbody tr th:nth-child(10)
{
  width:150px !important;
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: break-all;
}
#newsCarousel .carousel-control-prev, 
#newsCarousel .carousel-control-next{
     display:none;
 }

 /* -------------------------------------
 React pagination from using react-paginate
 ------------------------------------- */

 .pagination {
  /* margin: 0px auto; */
  display: flex;
  list-style: none;
  outline: none;
  font: inherit;
}
.pagination > .active > a{
  background-color: #006ec7 ;
  border-color: #006ec7 ;
  color: #fff;
}
.pagination > li > a{
  border: 1px solid #006ec7 ;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}
.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
  background-color: #006ec7 ;
  border-color: #006ec7;
  outline: none ;
}
.pagination > li > a, .pagination > li > span{
  color: #006ec7
}
.pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
  border-radius: unset;
  font-family: inherit;
}

/* Print BOL */
/*---receipt---*/
.imgInlogo{
  width:80px;
  height:auto;
}
.rcptBOL{
  text-transform:uppercase;
  /* letter-spacing: 0.1em; */
  word-spacing: 0.3em;
  font-family: 'Times New Roman', Times, serif;
  color: #000;
}
.rcptBOL p{
  font-size:12px;
  text-transform:uppercase;
  margin:3px 0;


}

.rcptBOL label{
  font-size:12px;
  text-transform:uppercase;
  margin:3px 0;
  font-weight:500;
  display:block;
}
.rcptBOL h4{
  font-size:20px;
  text-transform:uppercase;
  margin:0px;
  padding:0px;
  font-weight:600;
  font-family: inherit;
}
.rcptBOL h5{
  font-size:18px;
  text-transform:uppercase;
  margin:0px !important;
  padding:0px !important;
  font-family: inherit;
}
.rcptBOL h5 .dispThin{
  font-weight:300;
  font-size:16px;
}
.rcptBOL h6{
  font-size:16px;
  text-transform:uppercase;
  margin:0px !important;
  padding:0px !important;
}
.divBrdr{
  border:1px solid #ccc;
}
.rcptBOL .brdrBtm{
  border-bottom:1px solid #ccc;
}
.recNoTbl{
  width:100%;
  font-size:13px;

  }
.recNoTbl td{
  border-collapse:collapse;
  border:1px solid #ccc;
  padding:5px;
  text-align:center;
}



.redAdd td:first-child{
  width:10%!important;
}
.redAdd td:nth-child{
  width:50%!important;
}
.txtOrnt{
    writing-mode: vertical-lr;
      text-orientation: upright;
}
.orntSet{
  height:303px;
  border-right:1px solid #ccc;
}
.centAlgn{
  display: flex;
justify-content: center;
align-items: center;
}
.descTbl{
  border:1px solid #ccc;
      text-transform:uppercase;
}
.descTbl td, .descTbl th{
  border:1px solid #ccc;
  border-collapse:collapse;
  padding:10px;
}
.descTbl th{
  text-align:center;
  font-weight:bold;
  font-size:12px;
}
.descTbl td{
  font-weight:bold !important;
  font-size:14px;
}
.descTbl th:first-child{
      width:5%;
}
.descTbl th:nth-child(2){
      width:40%;
}
.descTbl th:nth-child(3), .descTbl th:nth-child(4),.descTbl th:nth-child(5){
      width:12%;
}
.descTbl td:nth-child(4), .descTbl td:nth-child(5),.descTbl td:nth-child(6){
      border-bottom:0px!important;
}
.descTbl td:first-child{
      text-align:right;
}
.minH260{
  min-height:220px;
}
.minH330{
  min-height:320px;
}

@media print {
  .rcptBOL, .rcptBOL *{
    color:#000000!important;
  }
.col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6,
.col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
     float: left;               
}
.col-lg-12 {
  width: 100%;
}

.col-lg-11 {
     width: 91.66666666666666%;
}
.col-lg-10 {
     width: 83.33333333333334%;
}
.col-lg-9 {
      width: 75%;
}

.col-lg-8 {
      width: 66.66666666666666%;
}

.col-lg-7 {
        width: 58.333333333333336%;
 }

 .col-lg-6 {
      width: 50%;
 }
.col-lg-5 {
      width: 41.66666666666667%;
 }
 .col-lg-4 {
      width: 33.33333333333333%;
 }
 
 .col-lg-3 {
      width: 25%;
 }

 .col-lg-2 {
        width: 16.666666666666664%;
 }

 .col-lg-1 {
        width: 8.333333333333332%;
  }  

  .rcptBOL h4{
text-transform:uppercase;
margin:0px;
padding:0px;
font-weight:600; 
}

.rcptBOL h5{
font-size:14px;
text-transform:uppercase;
margin:0px !important;
padding:0px !important;
}

.rcptBOL h5 .dispThin{
font-weight:300;
font-size:14px;
}

.rcptBOL h6{
font-size:15px;
text-transform:uppercase;
margin:0px !important;
padding:0px !important;
}

.rcptBOL p{
font-size:10px;
text-transform:uppercase;
margin:3px 0;
}

.rcptBOL label{
font-size:10px;
text-transform:uppercase;
margin:3px 0;
font-weight:600;
display:block;
}
.rcptBOL hr.my-2{
  border-top: #cccccc !important;
  }
.mb-2, .my-2 {
margin-bottom: 0.1rem!important;
}

.mt-2, .my-2 {
margin-top: 0.1rem!important;
}    

.orntSet{
height:auto;
border-right:1px solid #ccc;
}
}

@media (max-width: 767px) {
  .homeSlider {
    margin-top: 120px !important
  }
  .carousel-caption h2 {
    font-size: 20px;
    color: #fff;
}
  }

  @media (max-width: 576px) { 
    #sidebar, #sidebar.active {
      min-width: 40px;
      max-width: 40px;
      text-align: center;
    }
    #sidebar ul li a {
      font-size: 14px;
    }
    #sidebar.active {
      min-width: 40px;
      max-width: 40px;
      text-align: center; 
    }
    #sidebar.active ul.components li a i {
      margin-right: 0;
      display: block;
      font-size: 18px;
  }
  #content.pl-5{
    padding-left: 1rem !important;
  }
  .dataTables_wrapper .text-right{
    text-align: center!important;
  }
  }

  .form-control-InputFile {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0 0.75rem 0.375rem 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.3;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da !important;
    border-radius: 0.25em 0 0 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
input[type="file"]::file-selector-button {
  border: none;
  padding: 0.5rem 0.5rem;
  border-radius: 0.25em 0 0 0.25rem;
  transition: 1s;
  font-size:14px;
  line-height:1.3;
}

.input-group>.form-control-InputFile{
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group> .btnInpFile{
  border-radius:0 0.25em 0.25rem 0 ;
  background:#ced4da !important;
  padding:4px 15px;
  border: 1px solid #ced4da !important;
  outline:none;
}

.autocomplete-wrapper input{
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  /* font-size: 1rem !important; */
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.autocomplete-wrapper input:focus{
  outline: none;
}

.autocomplete-wrapper > div {
  width: 100%;
  display: block !important;
}

.autocomplete-wrapper .dropdown{
  border: 1px solid #ced4da;
  border-radius: 0 0 0.25rem;
  /* padding: 5px 10px; */
  background: #fff;
  position: absolute;
    z-index: 999;
    width: 93.5%;
    max-height: 150px;
    overflow: auto;
    cursor: pointer;
}

.autocomplete-item-highlight{
  background-color: #007dff;
  color: #fff;
  padding: 5px;
}

.autocomplete-item{
  padding: 5px;
}

.inlineText {
  font-size: 20px;
    text-transform: uppercase;
    margin: 0px;
    padding: 0px;
    font-weight: 600;
}
.inlineBackground {
  font-size: 18px;
    text-transform: uppercase;
    margin: 0px;
    padding: 0px;
    font-weight: 600;
  }

#innertable  {
  overflow-x:scroll;
  height:100%;
  width:100%;
  }

  #innertable tr th, #innertable tr td  {
    width: 150px;
  }

  .addscroll{
  overflow-x:scroll;
  height:100%;
  width:100%;
  }

  #airportTbl_wrapper  div.dataTables_scrollBody>table>tbody>tr>td {
    vertical-align: middle;
    color: red;
}

.maintenance{
  font-weight: bold;
  color: #3c3cb2;
	font-size:18px;
  text-align: center;
}
.alignCenter{
  align-content: center;
}
.modalLoadingOverlay {
  position: absolute;
  top: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  /* display: none; */
  background:  rgba(255, 255, 255, 0.4);
}

.modalLoadingSpinner {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.embossed{
  /* font-family: inherit; 
  font-size: 100px;
  color: #f0f0f0;
  border: 1px solid;
  border-color: #999999;*/
  font-weight: bold;
  color: #1f68b7;
  font-size: 15px;
  width: 100%;
  font-family: Futura;
  background-color: #bde4f3;
  text-shadow: 1px 4px 4px rgb(213, 227, 248);
  text-align: center;
  -webkit-background-clip: text;
    -moz-background-clip: text;

}
.placeholder
{
    position: relative;
}

.placeholder::after
{
    position: absolute;
    left: 50px;
    top: 3px;
    content: attr(data-placeholder);
    pointer-events: none;
    opacity: 0.6;
}
.alignRight{
  text-align: right;
}
.alignLeft{
  text-align: left;
}
.alignCenter{
  text-align: center;
}
.dflex{
  display: flex;
  justify-content: space-between;
}
/* 
#tariffEdit input {
  width: 50%;
}
#accessEdit input {
  width: 50%;
}
#specialEdit input {
  width: 50%;
} */
#tariffEdit label {
  color: black;
  font-weight: 500;
}
#accessEdit label {
  color: black;
  font-weight: 500;
}
#specialEdit label {
  color: black;
  font-weight: 500;
}

.pr200px{
  padding-right:200px
}
fieldset 
{
  border: 1px solid #ddd !important;
  margin: 0;
  min-width: 0;
  padding: 10px;       
  position: relative;
  border-radius:4px;
  background-color:#f9f9f963;
  padding-left:10px!important;
}	

  legend
  {
    font-size:14px;
    font-weight:bold;
    margin-bottom: 0px; 
    width: 35%; 
    border: 1px solid #ddd;
    border-radius: 4px; 
    padding: 5px 5px 5px 10px; 
    background-color: #0070c7;
    color: #fff;
  }
  .tabhr{
    height: 3px;
    border-width: 3px;
    background-color: #0070c7;
    margin-block-start: -1px;
    margin-block-end: 0px;
  }
  .mt15b0{
    margin-top: 15px;
    margin-bottom: 0px;
  }
  .passBorderRed{
    border: 2px solid red; 
  }
  .passBorderGreen{
    border: 2px solid green; 
  }
  .divBorderBlue{
    border: 2px solid #0070c7; 
  }
  .ml200{
  margin-left: 200px;
  }
 .menusize{
  max-width: 1420px;
 }
 .minusMargins{
 margin-top: -10px;
 margin-bottom: -15px;
 }
 .inv-feedback {
   width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  font-weight: bold;
  color: #dc3545;
}
.minusMarginTop{
  margin-top: -28px;
}
 
.local{
  height: 30px;
  width: 100%;
  background-color: yellow;
  color: blue;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  padding-top: 3px;
}
.I5{
  height: 30px;
  width: 100%;
  background-color: green;
  color: white;
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  padding-top: 3px;
}
.nationwide{
  height: 30px;
  width: 100%;
  background-color: red;
  color: white;
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  padding-top: 3px;
}