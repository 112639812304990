
.App {
   text-align: center; 
 
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  display: block;
  width: 100%;
}

/* 
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
} */
.form-control-invalid{
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem)!important;
   /*background-image: url(data:image/svg+xml,%3csvg bg-image='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e);
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); */
}


.parentDisable{
  /* position: relative;
  display: flex; */

  overflow: show;
  /* background: rgb(233, 232, 232); */
  /*
  opacity: 0.5;
  z-index: 998;
  height: 100vh;
  width: 100%;
*/
  /* makes horizontal center */
 /* justify-content: center;  
   makes vertical center
  align-items: center;     
  text-align: center;  */
  
  
  /* text-align: center; 
  background-color: rgba(255,255,255,0.7);
  display: flex;
  justify-content: center;
  align-items: center;
   width: 100%;
  height: 100vh;  */
 
}
.overlay-box {
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%, -50%);
  color: white; background: #666666; opacity: .8;
  z-index: 1000;
}


.parentDisable:before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(233, 232, 232, 0.5); ;
  z-index: 999;
  
}

.menu {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #cccccc;
}

.item {
  padding: 2px 6px;
  cursor: default;
}

.item-highlighted {
  color: white;
  background-color: #4095bf;
}

.item-header {
  background-color: #eeeeee;
  color: #454545;
  font-weight: bold;
}

.example {
  padding: 0 25px;
}

label {
  display: block;
  margin: 5px 0;
}

code {
  padding: .2em .5em;
  font-size: 85%;
  background-color: rgba(0,0,0,0.04);
  border-radius: 3px;
}